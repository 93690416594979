import {QueryList} from '../constants/api';
import {SERVER_JINGLE_URL} from '../constants/env';
import {getQuery, postQuery} from './hooks';
import {RequestPasswordWithCodeReq, ResetPasswordReq, SendResetPasswordEmailReq, UpdatePasswordReq} from './types';

export type loginRequest = {
  email: string;
  password: string;
};

export const loginQuery = async (req: loginRequest) => await postQuery(QueryList.login, '').send(req);
export const loginJingleQuery = async (req: loginRequest) =>
  await postQuery(QueryList.login, SERVER_JINGLE_URL).send(req);

export type authorizeRes = {
  active?: boolean;
  createdAt?: Date;
  email?: string;
  firstName?: string;
  id?: number;
  lastName?: string;
  phoneNumber?: string | null;
  timezone?: string | null;
  updatedAt?: Date;
  userType?: string;
};

export const authorizeQuery = async (token?: string) => await getQuery(QueryList.authorize, token);

//password
export const UpdatePassword = async (body: UpdatePasswordReq) => await postQuery(QueryList.updatePassword).send(body);
export const RequestPasswordWithCode = async (body: RequestPasswordWithCodeReq) =>
  await postQuery(QueryList.resetPasswordUsingCode).send(body);
export const SendResetPasswordEmail = async (body: SendResetPasswordEmailReq) =>
  await postQuery(QueryList.requestPasswordReset).send(body);
export const ResetPassword = async ({userId, body}: ResetPasswordReq) =>
  await postQuery(QueryList.resetPassword(userId)).send(body);
