import superagent from 'superagent';
import {SERVER_URL} from '../constants/env';
import {getToken} from '../hooks/auth';

export const postQuery = (query: string, customAPI?: string, customToken?: string) => {
  const bearerToken = getToken();
  if (bearerToken || customToken)
    return superagent
      .post(`${customAPI || SERVER_URL}${query}`)
      .set('Authorization', 'Bearer ' + (customToken || bearerToken));

  return superagent.post(`${customAPI || SERVER_URL}${query}`);
};

export const getQuery = (query: string, customToken?: string) => {
  const bearerToken = getToken();
  if (bearerToken || customToken)
    return superagent.get(`${SERVER_URL}${query}`).set('Authorization', 'Bearer ' + (customToken || bearerToken));

  return superagent.get(`${SERVER_URL}${query}`);
};
