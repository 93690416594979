import React, {useState} from 'react';
import {LoginFormTitle, LoginFormWrapper, SubmitBtn, StyledAuthInput, StyledAuthFormItem} from './styles';
import {Form} from 'antd';
import {useResetWithCode} from '../../hooks/auth';
import {ErrorLine, ErrorText} from '../Auth/styles';

export type NewPasswordFormPropsT = {
  resetData: useResetWithCode;
};

export const NewPasswordForm: React.FC<NewPasswordFormPropsT> = ({resetData}) => {
  const [loading, setLoading] = useState(false);
  const {resetWithCode, error} = resetData;
  const [form] = Form.useForm();
  const [result, setResult] = useState<number | boolean>(1);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const newPass = values?.password;
      const confirmPass = values?.confirm;
      if (newPass === confirmPass) {
        const res = await resetWithCode({newPassword: newPass});
        console.log(res);
        if (res) setResult(1);
        if (!res) setResult(0);
      } else {
        setResult(-1);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <LoginFormWrapper form={form} onFinish={onFinish} layout={'vertical'}>
      <LoginFormTitle>Forgot Password</LoginFormTitle>
      <StyledAuthFormItem
        label={'New Password'}
        name={['password']}
        rules={[{required: true, message: 'New password is requried!'}]}>
        <StyledAuthInput type={'password'} placeholder="Enter new password" />
      </StyledAuthFormItem>
      <StyledAuthFormItem
        label={'Confirm New Password'}
        name={['confirm']}
        rules={[{required: true, message: 'Confirm password is requried!'}]}>
        <StyledAuthInput type={'password'} placeholder="Confirm new password" />
      </StyledAuthFormItem>
      <ErrorLine>
        {result === 0 && <ErrorText>{error}</ErrorText>}
        {result === -1 && <ErrorText>Passwords do not match</ErrorText>}
      </ErrorLine>

      <StyledAuthFormItem>
        <SubmitBtn htmlType="submit" loading={loading}>
          Change Password
        </SubmitBtn>
      </StyledAuthFormItem>
    </LoginFormWrapper>
  );
};
